// Chakra imports
import { Box, Flex } from "@chakra-ui/react";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import Footer from "components/footer/FooterAuth";
import PropTypes from "prop-types";
import { Redirect, useLocation } from "react-router-dom";
import { useAuthStore } from "views/auth/store/auth.store";

function NotSignInRoute({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const isSignedIn = useAuthStore((state) => state?.session?.user?.id);
  if (location.pathname === "/auth/update-password") return <>{children}</>;
  else if (isSignedIn) return <Redirect to="/" />;
  return <>{children}</>;
}

function AuthIllustration(props: {
  children: JSX.Element | string;
  illustrationBackground: string;
}) {
  const { children, illustrationBackground } = props;
  // Chakra color mode
  return (
    <NotSignInRoute>
      <Flex position="relative" h="max-content">
        <Flex
          h={{
            sm: "initial",
            md: "unset",
            lg: "100vh",
            xl: "97vh",
          }}
          w="100%"
          maxW={{ md: "66%", lg: "1313px" }}
          mx="auto"
          pt={{ sm: "50px", md: "0px" }}
          px={{ lg: "30px", xl: "0px" }}
          ps={{ xl: "70px" }}
          justifyContent="start"
          direction="column"
        >
          {children}
          <Box
            display={{ base: "none", md: "block" }}
            h="100%"
            minH="100vh"
            w={{ lg: "50vw", "2xl": "44vw" }}
            position="absolute"
            right="0px"
          >
            <Flex
              bg={`url(${illustrationBackground})`}
              justify="center"
              align="end"
              w="100%"
              h="100%"
              bgSize="cover"
              bgPosition="50%"
              position="absolute"
              borderBottomLeftRadius={{ lg: "120px", xl: "200px" }}
            />
          </Box>
          <Footer />
        </Flex>
      </Flex>
    </NotSignInRoute>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
