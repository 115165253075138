import { Button, Image, Text } from "@chakra-ui/react";
import logoBlack from "assets/img/layout/commentify-logo.svg";
import { tw } from "twind";
import { signOut } from "views/auth/utils/auth.util";

function MainError() {
  return (
    <div
      className={tw(
        `h-screen w-screen flex flex-col justify-center items-center gap-10`
      )}
    >
      <Image src={logoBlack} alt="Logo" w="250px" />
      <Text fontSize="lg">
        Oopsie! Looks like something got a bit wonky. Give it a few minutes and
        try again !
      </Text>
      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        minW="150"
        h="50"
        onClick={signOut}
      >
        Logout
      </Button>
    </div>
  );
}

export default MainError;
